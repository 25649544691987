<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'
import { usePlayerStore } from '~/stores/usePlayerStore'
import Hls from 'hls.js'

const playerStore = usePlayerStore()

const inProgress = ref(false)
const hls = ref<Hls | null>(null)

const playerElement = useTemplateRef<HTMLVideoElement>('playerElement')

const handleLoadStart = () => {
  // console.log('loadstart event triggered')
  playerStore.isLoading = true
}

const handleCanPlay = () => {
  // console.log('canplay event triggered', playerStore.isPlaying)
  if (playerStore.isPlaying) {
    play()
  }
}

const handleLoadedMetadata = () => {
  // console.log('loadedmetadata event triggered')
  if (playerStore.isPlaying) {
    play()
  }
}

const handleWaiting = () => {
  // console.log('waiting event triggered')
  playerStore.isLoading = true
}

const handleError = () => {
  // console.log('error event triggered')
  playerStore.stop()
}

const handleEnded = () => {
  playerStore.stop()

  if (playerStore.preRoll) {
    fetchTrigger(playerStore.preRoll.triggers.complete)
    playerStore.preRoll = null
    playerStore.play()
  }
}

const handleDurationChange = () => {
  if (playerElement.value?.duration && isFinite(playerElement.value.duration)) {
    playerStore.duration = Math.round(playerElement.value?.duration)
  } else {
    playerStore.duration = 0
  }
  // console.log('durationchange event triggered', playerStore.duration)
}

const handleTimeUpdate = () => {
  const time = playerElement.value?.currentTime ? Math.round(playerElement.value?.currentTime) : 0

  if (playerStore.currentTime !== time) {
    if (playerStore.preRoll) {
      handlePreRollTriggers(time)
    }

    playerStore.currentTime = time
  }
}

const fetchTrigger = async (trigger: string) => {
  try {
    await $fetch(trigger)
  } catch (e) {
    console.error(e)
  }
}

const handlePreRollTriggers = (time: number) => {
  if (!playerStore.preRoll) {
    return
  }

  if (time === 1) {
    fetchTrigger(playerStore.preRoll.triggers.impression)
    fetchTrigger(playerStore.preRoll.triggers.start)
  }

  if (time === Math.ceil((playerStore.duration + 1) / 4)) {
    fetchTrigger(playerStore.preRoll.triggers.firstQuartile)
  }

  if (time === Math.ceil((playerStore.duration + 1) / 2)) {
    fetchTrigger(playerStore.preRoll.triggers.midpoint)
  }

  if (time === Math.ceil(((playerStore.duration + 1) * 3) / 4)) {
    fetchTrigger(playerStore.preRoll.triggers.thirdQuartile)
  }
}

const play = () => {
  if (inProgress.value) {
    return
  }

  playerElement.value
    ?.play()
    .then(() => {
      inProgress.value = false
      playerStore.isLoading = false
    })
    .catch((error) => {
      inProgress.value = false
      console.error('Play promise rejected', error)
      playerStore.stop()
    })

  inProgress.value = true
}

onMounted(() => {
  if (playerElement.value) {
    playerElement.value.addEventListener('loadstart', handleLoadStart)
    playerElement.value.addEventListener('canplay', handleCanPlay)
    playerElement.value.addEventListener('loadedmetadata', handleLoadedMetadata)
    playerElement.value.addEventListener('waiting', handleWaiting)
    playerElement.value.addEventListener('error', handleError)
    playerElement.value.addEventListener('ended', handleEnded)
    playerElement.value.addEventListener('durationchange', handleDurationChange)
    playerElement.value.addEventListener('timeupdate', handleTimeUpdate)
  }
})

onUnmounted(() => {
  if (playerElement.value) {
    playerElement.value.removeEventListener('loadstart', handleLoadStart)
    playerElement.value.removeEventListener('canplay', handleCanPlay)
    playerElement.value.removeEventListener('loadedmetadata', handleLoadedMetadata)
    playerElement.value.removeEventListener('waiting', handleWaiting)
    playerElement.value.removeEventListener('error', handleError)
    playerElement.value.removeEventListener('ended', handleEnded)
    playerElement.value.removeEventListener('durationchange', handleDurationChange)
    playerElement.value.removeEventListener('timeupdate', handleTimeUpdate)
  }
})

watch(
  () => playerStore.currentTime,
  (currentTime) => {
    if (playerElement.value?.currentTime && playerElement.value?.duration) {
      const time = Math.round(playerElement.value?.currentTime)

      if (currentTime !== time) {
        // console.log('update currentTime', currentTime, time)
        playerElement.value.currentTime = currentTime
      }
    }
  }
)

watch(
  () => playerStore.source,
  (newSource) => {
    if (playerElement.value) {
      playerStore.isLoading = true
      playerElement.value.pause()

      if (!playerStore.isStreaming) {
        playerElement.value.src = newSource || ''
      } else {
        if (Hls.isSupported()) {
          hls.value = new Hls()
        }
      }
    }
  }
)

watch(
  () => hls.value,
  () => {
    if (hls.value && playerElement.value) {
      hls.value.loadSource(playerStore.source)
      hls.value.attachMedia(playerElement.value)
      hls.value.on(Hls.Events.MANIFEST_PARSED, () => {
        play()
      })

      hls.value.on(Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.MEDIA_ERROR:
              hls.value.recoverMediaError()
              break
            case Hls.ErrorTypes.NETWORK_ERROR:
              hls.value.destroy()
              handleError()
              break
            default:
              hls.value.destroy()
              handleError()
              break
          }
        }
      })
    }
  }
)

watch(
  () => playerStore.isPlaying,
  (isPlaying) => {
    if (playerStore.isLoading) {
      return
    }

    if (isPlaying) {
      play()
    } else {
      playerElement.value?.pause()
    }
  }
)

watch(
  () => playerStore.volume,
  (newVolume) => {
    if (playerElement.value) {
      playerElement.value.volume = newVolume
    }
  }
)
</script>

<template>
  <video id="media-player" ref="playerElement" class="hidden" playsInline />
</template>

<style scoped></style>
